<template>
  <div>

    <div v-if="product.category2_name==='플랫폼 팩'">
      <!-- 무료체험 - 가격안내 -->
      <div class="price-info-wrapper">
        <template v-if="!userService || userService.service_order.trial">
          <div class="h8 sub">가격안내</div>
          <div class="box-price-info">
          <div v-for="(sc_item, sc_idx) in subscriptions"
               :key="`sc-${sc_idx}`"
               class="item-price-info">
            <div class="flex-align">
              <img src="/static/icon/u_check.svg" class="svg-primary">
              <div class="size-14 sub">{{ sc_item.date_unit + '개월' }}</div>
            </div>
            <div class="size-16">
              <template v-if="sc_item.discount_type===0 && sc_item.discount_rate>0">
                <span class="margin-right-8 size-13 sub4 text-line-through">{{ product.price.price|currency }}</span>
                <span class="margin-right-4 primary body4-bold">{{ sc_item.discount_rate }}%</span>
              </template>
                <span class="weight-700 main">{{ sc_item.price | currencyNum }}</span>
                <span class="size-14 sub">원</span>
                <span class="size-14 sub3">/월</span>
            </div>
          </div>
        </div>
        </template>
        <template v-else>
          <div class="h8 sub margin-bottom-16">결제방법 선택</div>
          <div class="plan-select-wrapper">
            <div class="option-st unselect"
                 v-for="(sc_item, sc_idx) in subscriptions"
                 :key="`sc-${sc_idx}`"
                 :class="{'selected': selectedPlan && selectedPlan.date_unit===sc_item.date_unit}"
                 @click="selectPlan(sc_item)">
              <div class="body2-medium sub">{{ sc_item.date_unit }}개월 플랜</div>
              <div class="body6 sub4 text-line-through" v-if="sc_item.discount_type===0 && sc_item.discount_rate>0">{{ product.price.price | currency }}</div>
              <div>
              <span class="margin-right-4 primary body4-bold" v-if="sc_item.discount_type===0 && sc_item.discount_rate>0">
                {{ sc_item.discount_rate }}%
              </span>
                <span class="body4-bold main">{{ sc_item.price | currencyNum }}</span>
                <span class="body4 sub">원</span>
                <span class="body4 sub3">/월</span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- 무료체험 - 가격안내 -->
    <div v-else-if="!userService || userService.service_order.trial || product.category2_name === '추가서비스' || isPrime"
      class="price-info-wrapper">
      <div class="h8 sub">가격안내</div>
      <!-- 옵션 있을 때 -->
      <div class="box-price-info" v-if="optionArr1.length>0">
        <div v-for="(sc_item, sc_idx) in optionArr1[0].options"
             :key="`sc-${sc_idx}`"
             class="item-price-info">
          <div class="flex-align">
            <img src="/static/icon/u_check.svg" class="svg-primary">
            <div class="size-14 sub">{{ sc_item.name }}</div>
          </div>
          <div class="size-16">
            <template v-if="sc_item.is_discount && sc_item.discount_rate>0">
              <span class="margin-right-8 size-13 sub4 text-line-through">{{ sc_item.origin_price|currency }}</span>
              <span class="margin-right-4 primary body4-bold">{{ sc_item.discount_rate }}%</span>
            </template>
            <span class="weight-700 main">{{ sc_item.price | currencyNum }}</span>
            <span class="size-14 sub">원</span>
            <span class="size-14 sub3" v-if="sc_item.is_subscription">/월</span>
          </div>
        </div>
      </div>
      <!-- 옵션 없을 때 -->
      <div class="box-price-info" v-else>
        <div class="item-price-info"
             :style="`justify-content: ${product.price.price_type === 0 ? 'flex-end' : 'space-between'}`">
          <template v-if="product.price.price_type === 0">
            <div class="size-16">
                <template v-if="product.price.is_discount && product.price.discount_rate>0">
                  <span class="margin-right-8 size-13 sub4 text-line-through">{{ product.price.price|currency }}</span>
                  <span class="margin-right-4 primary body4-bold">{{ product.price.discount_rate }}%</span>
                </template>
                <span class="weight-700 main">{{ product.price.sale_price | currencyNum }}</span>
                <span class="size-14 sub">원</span>
                <span class="size-14 sub3" v-if="product.price.is_subscription">/월</span>
            </div>
          </template>
          <template v-else>
            <div class="flex-align">
              <img src="/static/icon/u_check.svg" class="svg-primary">
              <div class="size-16 weight-500 sub">{{ product.price.price_text }}</div>
            </div>
            <button class="button is-primary-light2"
                    style="width:92px;height:30px;" @click="clickInquiry">문의하기</button>
          </template>
        </div>
      </div>
    </div>

    <template v-if="userService && !userService.service_order.trial && product.category2_name !== '추가서비스' && !isPrime">
      <div v-if="product.enable_option">
        <div class="lp-divider-gray1 padding-top-24"></div>
        <!-- 필수옵션 -->
        <template v-if="product.category2_name!=='플랫폼 팩'">
          <div v-for="(selector, idx) in optionArr1" :key="`option1-${idx}`">
            <div v-if="!selector.disabled">
              <div class="h8" style="margin:20px 0 12px 0">{{ selector.name }}</div>
              <div class="plan-select-wrapper">
                <!-- 옵션 정보 -->
                <template v-for="(option, o_idx) in selector.options">
                  <div v-if="!option.disabled" :key="`op1-${o_idx}`"
                       class="option-st unselect"
                       :class="{'disabled': selector.disabled, 'selected': option.selected}"
                       @click="selectOption1(option, selector)">
                    <div class="body2-medium sub" v-html="option.name"></div>
                    <div class="body6 sub4 text-line-through" v-if="option.is_discount && option.discount_rate>0">{{ option.origin_price | currency }}</div>
                    <div v-if="option.price>0">
                        <span class="margin-right-4 primary body4-bold" v-if="option.is_discount && option.discount_rate">
                          {{ option.discount_rate }}%
                        </span>
                      <span class="body4-bold main">{{ option.price | currencyNum }}</span>
                      <span class="body4 sub">원</span>
                      <span class="body4 sub3" v-if="option.is_subscription">/월</span>
                    </div>
                    <div class="body5 sub3 margin-top-4">{{ option.desc }}</div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>

        <!-- 추가옵션 -->
        <div v-if="optionArr2 && optionArr2.length>0">
          <div v-for="(selector, idx) in optionArr2" :key="`option2-${idx}`">
            <div class="h8" style="margin:20px 0 12px 0">{{ selector.name }}</div>
            <!-- 옵션 정보 -->
            <div v-for="(option, o_idx) in selector.options" :key="`op2-${o_idx}`">
              <div class="option-st unselect"
                   :class="{'selected': option.selected}"
                   @click="selectOption2(option)">
                <div class="flex-between">
                  <div class="body2-medium sub" v-html="option.name"></div>
                  <div class="margin-top-4" v-if="option.price>0">
                    <span class="body4-bold main">+{{ option.price | currencyNum }}</span>
                    <span class="body4 sub">원</span>
                    <span class="body4 sub3" v-if="option.is_subscription">/월</span>
                  </div>
                </div>
                <div class="body5 sub3 margin-top-4">{{ option.desc }}</div>
              </div>
            </div>
          </div>
        </div>
        <!--<div class="flex-between padding-top-24">
          <div class="subtitle5">
            <span class="sub2">금액<span class="primary" v-if="order_product.is_subscription">(정기 결제)</span></span>
          </div>
          <div>
              <span class="body2-bold main">
                {{ order_product.total_price| currencyNum }}
              </span>
            <span class="body4 sub">원<span class="sub3" v-if="order_product.is_subscription">/월</span></span>
          </div>
        </div>-->

        <div class="lp-divider-gray1 padding-top-24"></div>
        <div class="flex-between padding-top-24">
          <div class="h7">총 결제금액</div>
          <div class="h4">{{ order_product.total_price | currencyNum }}<span class="body2 main">원</span>
            <span class="body2 sub3">
              <template v-if="product.category2_name === '플랫폼 팩' && selectedPlan">/
                <template v-if="selectedPlan.date_unit>1">{{ selectedPlan.date_unit }}개월</template>
                <template v-else>월</template>
              </template>
              <template v-else-if="order_product.is_subscription">/월</template>
            </span>
          </div>
        </div>
        <!-- 플랫폼 팩 고지 -->
        <div class="margin-top-24" v-if="product.category2_name === '플랫폼 팩'">
          <div v-for="(item,idx) in info" :key="'info-'+idx" class="flex" style="margin-bottom:8px">
            <img src="/static/icon/fi_alert-circle.svg" class="svg-primary" style="margin:4px 6px 0 0;width:20px;height:20px">
            <div class="body4 sub3">{{ item }}</div>
          </div>
        </div>
      </div>
      <!-- 자체 상품 -->
      <div v-else-if="product.price.price_type === 0">
        <div class="lp-divider-gray1 padding-top-24"></div>
        <div class="flex-between padding-top-24">
          <div class="h7">총 결제금액</div>
          <div class="h4">{{ discountedPrice | currency }}</div>
        </div>
      </div>
    </template>

    <!-- 버튼 영역 -->
    <div class="padding-top-40">
      <template v-if="isReq">
        <button class="button is-gray2 body2-bold" style="width:100%;height:52px;cursor:not-allowed" >신청 중</button>
      </template>
      <template v-else-if="product.category2_name === '추가서비스' || isPrime">
        <div style="margin:-20px 0 20px 0">
          <div v-if="product.category2_name === '플러그인'" class="flex-align" style="margin-bottom:8px">
            <img src="/static/icon/fi_alert-circle.svg" class="svg-primary" style="width:20px;margin-right:4px">
            <div class="body4 sub3">수동설치 플러그인은 최소 4주의 작업기간이 필요합니다.</div>
          </div>
          <div class="flex-align">
            <img src="/static/icon/fi_alert-circle.svg" class="svg-primary" style="width:20px;margin-right:4px">
            <div class="body4 sub3">결제와 세부 절차는 신청 후 1:1문의로 안내드립니다.</div>
          </div>
        </div>

        <template v-if="!userService || userService.service_order.trial">
          <button class="button button-not-allowed body2-bold">무료체험 기간 중 추가 불가</button>
        </template>
        <button v-else class="button is-primary body2-bold"
                style="width:100%;height:52px" @click="clickRequest">신청하기</button>
      </template>
      <template v-else-if="used">
        <button class="button is-dark body2-bold" style="width:100%;height:52px" >
          <svg width="18" height="18" viewBox="0 0 24 24" fill="none" style="vertical-align: sub;margin-right: 4px;">
            <path d="M20 6L9 17L4 12" stroke="#ffffff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>이용 중</button>
      </template>
      <template v-else>
        <button class="button is-primary body2-bold" style="width:100%;height:52px" @click="saveData">
          {{ userService && userService.service_order.trial ? '무료체험' : '구매하기' }}</button>
        <div class="body2 sub2 margin-top-16 text-center">
          <template v-if="userService &&
                userService.service_order.trial">무료체험기간 동안 무료로 이용가능하며, 갱신일에 금액이 추가됩니다.</template>
          <template v-else>다음 결제일까지 무료 / 이후 결제금액 청구 (부가세 별도)</template>
        </div>

      </template>
    </div>

    <lp-process-modal headTitle="앱마켓"
                      v-bind="modalInfo"
                      v-if="vModal" @close="vModal=false"></lp-process-modal>

    <lp-process-modal headTitle="앱마켓 추가 신청"
                      v-bind="modalReqInfo" :req="true"
                      :buttonLeft="{'text': '신청 내역 보기', 'link': 'close'}"
                      :visibleRight="false"
                      v-if="vModalReq" @close="onCloseReq"></lp-process-modal>
  </div>
</template>
<script>
  import OrderMixin from "../../mixins/OrderMixin";
  import LpProcessModal from "../modal/LpProcessModal";
  import UserAPIMixin from "../../mixins/UserAPIMixin";
  import ServiceAdminAPIMixin from "../../mixins/ServiceAdminAPIMixin";

  export default {
    name: "MarketOptionSelector",
    components: {LpProcessModal},
    mixins: [
      OrderMixin,
      UserAPIMixin,
      ServiceAdminAPIMixin
    ],
    props: {
      product: {
        type: Object
      }
    },
    created() {
      this.init();
      if(this.product.category2_name === '플랫폼 팩') {
        let items = [];
        let item = {
          date_unit:1,
          discount_rate:0,
          discount_type:0,
          price:this.product.price.price
        }
        items.push(item);
        items = items.concat(this.product.price.subscriptions);
        this.subscriptions = items;
      }
    },
    data() {
      return {
        vModal: false,
        vModalReq: false,
        // 비교할 상품 옵션
        selectorStocks: [],
        // 필수옵션
        optionArr1: [],
        // 추가옵션
        optionArr2: [],
        order_product: undefined,
        // 필수값 선택값 체크용
        requiredOptionsValue: [],
        info: [
          '팩 추가 시, 화면 빌더에서 해당하는 페이지의 페이지 보드로 추가됩니다.',
          '구매한 팩의 주문화면으로 나오게 할 상품 카테고리를 관리자에서 지정해 주어야 합니다.',
          '다음 결제일 이전에 별도의 해지신청이 없는 경우, 다음 결제일에 해당 금액만큼 추가되어 자동으로 청구됩니다.',
          '케어서비스 선택 시 충전금에서 차감되며, 케어서비스를 이용하고 있지 않은 고객님께서 신청하신 경우는 별도로 연락 드려 안내 드립니다.'
        ],
        // 플랜 목록
        subscriptions: [],
        // 플랜 선택
        selectedPlan: undefined,
        modalInfo: {
          title: '가입 및 서비스 개설 후 이용가능합니다',
          content: '가입 후 서비스를 개설하신 뒤 앱마켓을 이용하실 수 있습니다.',
          buttonLeft: {
            text: '닫기',
            link: 'close'
          },
          buttonRight: {
            text: '회원가입하기',
            link: '/signup'
          },
        },
        modalReqInfo: {
          title: '수동 설치 기능 신청이 완료되었습니다.',
          content: '최소 4주의 작업기간이 필요하며,\n' +
            ' , 런치팩 1:1 문의를 통해 문의해주시면 필요한 절차와\n' +
            '세부 설치 일정을 안내해드립니다. :)',
          buttonLeft: {
            text: '1:1 문의하기',
            link: ''
          },
          buttonRight: {
            text: '내역 확인하기',
            link: '/app_market/order_list'
          },
        },
        serviceCount: 0,
        serviceKey: '',
        isReq: false,
        inquiryId: 0          // 런치팩 1:1 문의 id
      }
    },
    computed: {
      // Prime 여부
      isPrime() {
        let grade = this.product.params.filter(i=>{ return i.name === '플러그인 등급' });
        if(grade.length===0) {
          return false;
        }
        grade = grade[0];
        let val = grade.choice_items.filter(i=>{ return i.id === grade.value_select[0]});
        if(val.length === 0) {
          return false;
        } else if(val[0].name === 'Prime') {
          return true;
        }
        return false;
      },
      // 이용중 여부
      used() {
        let key = this.product.params.filter(i => { return i.name === 'key'})[0].value_text;
        let service_key = '';
        if(this.product.category2_name === '플랫폼 팩') {
          service_key = 'packages';
        } else if(this.product.category2_name === '플러그인') {
          service_key = 'plugins';
        }

        return service_key === '' || !this.userService ? false : this.userService[service_key].indexOf(key)>-1;
      },
      // 할인 여부
      isDiscount() {
        if (this.product.price.is_discount && this.product.price.discount_start !== null && this.product.price.discount_end !== null) {
          let currentDate = this.dayjs().format('YYYY-MM-DD HH:mm');
          return !(currentDate < this.product.price.discount_start || currentDate > this.product.price.discount_end);
        } else if (this.product.price.discount_start !== null && this.product.price.discount_end === null) {
          let currentDate = this.dayjs().format('YYYY-MM-DD HH:mm');
          return currentDate >= this.product.price.discount_start;
        } else if (this.product.price.discount_start === null && this.product.price.discount_end !== null) {
          let currentDate = this.dayjs().format('YYYY-MM-DD HH:mm');
          return currentDate <= this.product.price.discount_end;
        } else {
          return true;
        }
      },
      // 상품 가격
      discountedPrice() {
        let total = 0;
        if (this.isDiscount) {
          if (this.product.price.discount_rate !== 0 && this.product.price.discount_type === 0) {
            total = this.product.price.price * (1 - (this.product.price.discount_rate / 100))
          } else if (this.product.price.discount_price !== 0 && this.product.price.discount_type === 1) {
            total = this.product.price.price - this.product.price.discount_price;
          }
        } else total = this.product.price.price;
        return total;
      },
    },
    methods: {
      init() {
        this.order_product = {
          product_id: this.product.id,
          quantity: 1,
          discount_price: 0,
          product_price: 0,
          total_price: 0,
          product: this.product,
          order_product_option: [],
          required_option_price: 0,
          add_option_price: 0,
          is_subscription: this.product.price.is_subscription
        }
        this.initOptions();
        this.getData();
        this.setIsReq();
      },
      initOptions() {
        // 옵션 초기화
        this.optionArr1 = this.product.selectors.filter(i => i.option_type === 0).map((i,idx) => {
          i.disabled = idx > 0;
          return i;
        });
        this.optionArr2 = this.product.selectors.filter(i => i.option_type === 1);
      },
      // 신청중 여부
      setIsReq() {
        if(!this.userService || this.$store.getters.admin.id === 0) return false;
        this.request.serviceAdmin.get(`launchpack/v1/service/${this.userService.id}/app_order?category=${this.product.category2}&status=1`).then(res=>{
          this.isReq = res.data.some(i=> i.products[0].id === this.product.id);
        });
      },
      getData() {
        // 개설한 서비스 수 가져오기
        if(this.isLogin) {
          this.request.user.get('/launchpack/service').then(res=>{
            this.serviceCount = res.data.data.length;
            if(this.serviceCount>0) {
              this.serviceKey = res.data.data[0].key;
            }
          });
        }

        let url = `public/product/${this.product.id}/selector_stocks`
        // let url = `http://localhost:8001/public/product/${this.product.id}/selector_stocks`
        this.$axios.post(url).then(res=>{
          this.selectorStocks = res.data.stocks.map(item=>{
            let price = 0;
            item.options.forEach(opt => {
              if(this.optionArr1.length>0) {
                price += this.optionArr1[0].options.find(o => o.id === opt).price;
              }
            });
            item.price = price;
            return item;
          });
        })
      },
      selectedOptionName(selectorStocks, option_id) {
        if(!selectorStocks) return;
        let stock = this.findItem(selectorStocks, 'id', option_id);
        if (stock) return stock.price > 0 ? `${stock.name}: +${stock.price}원` : `${stock.name}: ${stock.price}원`;
      },
      // 추가옵션 선택
      selectOption2(option) {
        if(!option.selected) {
          option.selected = true;
          this.selectorStocks.forEach(stock=>{
            if(stock.options[0]===option.id) {
              let option = this.findItem(this.order_product.order_product_option, 'option_id', stock.id);
              if(!option) {
                this.order_product.order_product_option.push({
                  option_id: stock.id,
                  quantity: 1,
                  option_type: stock.option_type
                });
              }
            }
          })
        } else {
          option.selected = false;
          this.selectorStocks.forEach(stock=>{
            if(stock.options[0]===option.id) {
              let option_index = this.order_product.order_product_option.findIndex(opo=> opo.option_id === stock.id);
              if(option_index>-1) {
                this.order_product.order_product_option.splice(option_index, 1);
              }
            }
          })
        }
        this.calcTotalPrice();
      },
      // 필수옵션 선택
      selectOption1(option, selector) {
        let option_id = option.id;
        if(!option_id) return;
        // 옵션 선택시 false로 초기화 후 옵션 선택
        selector.options.forEach(op=>{
          op.selected = false;
        });
        option.selected = true;

        this.selectorStocks.forEach(stock=>{
          if(stock.options.indexOf(option.id) > -1) {
            this.initOrderProductOption();
            this.order_product.order_product_option.push({option_id: stock.id, quantity: 1, option_type: stock.option_type});
          }
        })

        // 건 or 월 저장
        this.order_product.is_subscription = option.is_subscription;
        this.order_product.date_unit = option.is_subscription ? 1 : 0;
        this.calcTotalPrice();
      },
      calcTotalPrice() {
        let total = 0;
        let requiredOptionPrice = 0;
        let addOptionPrice = 0;
        let planPrice = 0;
        if(this.product.category2_name==='플랫폼 팩' && this.selectedPlan) {
          planPrice = this.selectedPlan.price * this.selectedPlan.date_unit;
          this.order_product.date_unit = this.selectedPlan.date_unit;
          total += planPrice;
        }
        // 상품 옵션일 경우
        if(this.product.enable_option) {
          // required_option_price = 필수옵션
          // add_option_price = 추가옵션
          // total_price = 필수옵션 + 추가옵션
          this.order_product.order_product_option.forEach(item=> {
            let op = this.selectorStocks.find(i => i.id === item.option_id);
            if(op) {
              if(item.option_type===0) {
                requiredOptionPrice += op.price * parseInt(item.quantity)
              } else if(item.option_type===1) {
                if(op.name!=='전문가 확인 신청') {
                  addOptionPrice += op.price * parseInt(item.quantity)
                }
                addOptionPrice += op.price * parseInt(item.quantity)
              }
              total += op.price * parseInt(item.quantity)
            }
          })
        }
        // 자체 상품일 경우
        else {
          total = this.discountedPrice * this.order_product.quantity;
        }
        if(this.product.category2_name==='플랫폼 팩') {
          this.order_product.product_price = planPrice;
        } else if(this.product.enable_option) {
          this.order_product.product_price = requiredOptionPrice;
        } else {
          this.order_product.product_price = total;
        }
        this.order_product.required_option_price = requiredOptionPrice;
        this.order_product.add_option_price = addOptionPrice;
        this.order_product.total_price = total;
      },
      initOrderProductOption() {
        let opoIndex = this.order_product.order_product_option.findIndex(opo=> opo.option_type===0)
        if(opoIndex>-1) this.order_product.order_product_option.remove(opoIndex);
      },
      onCloseReq() {
        this.vModalReq=false;
        //this.routerPush('/app_market/list');
        //window.parent.opener.postMessage({close: true, inquiry:true, inquiryId: this.inquiryId}, '*');
        window.open(`https://${this.serviceKey}.launchpack.co.kr/office/admin/launchpack/inquiry/customer/create?id=${this.inquiryId}`,'_blank');
      },
      marketAction() {
        // 공홈 > 앱마켓 에서 들어온 경우 조건 처리

        if(!this.isLogin) {
          this.setModalInfo(
            '가입 및 서비스 개설 후 이용가능합니다',
            '가입 후 서비스를 개설하신 다음 앱마켓을 이용하실 수 있습니다.',
            { text: '닫기', link: 'close' },
            { text: '회원가입하기', link: '/signup' }
          );
        } else if(this.serviceCount === 0) {
          this.setModalInfo(
            '서비스 개설 후 이용가능합니다',
            '내 서비스를 개설하러 가시겠습니까?',
            { text: '닫기', link: 'close' },
            { text: '내 서비스 개설하기', link: '/service_create?step=1' }
          )
        } else if(this.serviceCount === 1) {
          this.setModalInfo(
            '내 서비스 앱마켓으로 이동합니다',
            '내 서비스 앱마켓에서 이용가능합니다.\n내가 개설한 서비스의 앱마켓으로 이동하시겠습니까?',
            { text: '닫기', link: 'close' },
            { text: '내 서비스 앱마켓 열기', link: `https://${this.serviceKey}.launchpack.co.kr/office/admin?app_market=true` }
          )
        } else {
          this.setModalInfo(
            '내가 개설한 서비스 관리 메뉴에서 추가할 수 있습니다.',
            '내가 개설한 서비스 관리 메뉴에서 앱마켓 이용이 가능합니다.\n내 서비스 목록으로 이동하시겠습니까?',
            { text: '닫기', link: 'close' },
            { text: '내 서비스 목록', link: '/mypage/my_service' }
          )
        }

        this.vModal = true;
      },
      setModalInfo(title, content, left, right) {
        this.modalInfo = {
          title: title,
          content: content,
          buttonLeft: left,
          buttonRight: right
        }
      },
      clickInquiry() {
        window.parent.opener.postMessage({close: true, inquiry:true, cat:3}, '*');
      },
      getParams() {
        if(this.product.category2_name==='플랫폼 팩') {
          if(!this.userService.service_order.trial && !this.selectedPlan) {
            this.toast('플랜을 선택하세요.');
            return undefined;
          }
        } else if(!this.userService.service_order.trial && this.product.category2_name !== '추가서비스') {
          if(this.optionArr1.length > 0 && this.order_product.order_product_option.length === 0) {
            this.toast('옵션을 선택해주세요.');
            return undefined;
          }
        }
        let params = {
          type: 'market',
          order_product: this.cloneItem(this.order_product),
          subscribe_price: 0
        };
        if(!this.userService.service_order.trial) {
          if(this.product.category2_name === '플랫폼 팩') {
            params.subscribe_price = this.order_product.product_price + this.order_product.add_option_price;
          } else {
            params.subscribe_price = this.order_product.required_option_price + this.order_product.add_option_price;
          }
        }

        return params;
      },
      saveData() {
        if(this.$route.path === '/market/detail') {
          this.marketAction();
        }
        // 빌더, 백오피스에서 iframe으로 호출 된 경우
        else {
          let params = this.getParams();
          if(params) {
            this.$store.commit('setBasket', this.getParams());
            this.routeGa('', this.product.title, '앱마켓 체험하기');
            this.routerPush('account', true);
          }
        }
      },
      clickRequest() {
        if(this.$route.path === '/market/detail') {
          this.marketAction();
        } else {
          this.routeGa('', this.product.title, '신청하기');

          let params = this.getParams();
          if(params) {
            let order_product = params.order_product;
            params = {
              status: 1,
              subscribe_price: params.subscribe_price,
              pay_type: 1,
              product: {
                product_id: order_product.product.id,
                quantity: 1,
                discount_price: order_product.product.price.discount_price,
                discount_rate: order_product.product.price.discount_rate,
                total_price: order_product.total_price,
                product_price: order_product.product_price,
                is_subscription: params.order_product.is_subscription
              },
              date_unit: order_product.date_unit,
              is_subscription: params.order_product.is_subscription
            }
            this.setLoading('신청 중입니다');
            this.request.serviceAdmin.post(`launchpack/v1/service/${this.userService.id}/app_order`, params).then(res=>{
              this.clearLoading();
              if(res.status === 200) {
                this.inquiryId = res.data.inquiry_id;

                // 앱마켓 추가 신청 모달 띄우기
                this.modalReqInfo.buttonLeft.link = `https://${this.serviceKey}.launchpack.co.kr/office/admin/launchpack/inquiry/customer/create`;

                if(this.product.category2_name === '추가서비스') {
                  this.modalReqInfo.title = '추가서비스 신청이 완료되었습니다.';
                  this.modalReqInfo.content = '서비스별 별도 작업기간이 필요하며,\n' +
                    '런치팩 1:1 문의를 통해 문의해주시면 필요한 절차와\n' +
                    '세부 설치 일정을 안내해드립니다. :)';
                } else {
                  this.modalReqInfo.title = '수동 설치 기능 신청이 완료되었습니다.';
                  this.modalReqInfo.content = '최소 4주의 작업기간이 필요하며,\n' +
                    '런치팩 1:1 문의를 통해 문의해주시면 필요한 절차와\n' +
                    '세부 설치 일정을 안내해드립니다. :)';
                }

                this.vModalReq = true;
              }
            }).catch(()=>{
              this.clearLoading();
            });
          }
        }
      },
      selectPlan(item) {
        this.selectedPlan = item;
        this.calcTotalPrice();
      }
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .option-st
    border 1px solid $gray1
    border-radius 8px
    padding 16px 24px
  .option-st:hover
    border 1px solid $sub3

  .disabled
    background-color #eee

  .selected
    border 2px solid $primary !important

  .plan-select-wrapper
    display grid
    grid-gap 12px
    grid-template-columns repeat(2, 1fr)

  .price-info-wrapper
    border-top 1px solid $gray1
    margin-top 20px
    padding-top 20px

  .box-price-info
    margin-top 12px
    border 1px solid $gray1
    padding 16px 20px
    .item-price-info
      display flex
      align-items center
      justify-content space-between
      padding 12px 0
      border-bottom 1px solid $gray2
      img
        width 16px
        margin-right 4px
    .item-price-info:first-child
      padding-top 0
    .item-price-info:last-child
      padding-bottom 0
      border-bottom 0

  .button-not-allowed
    background-color $gray3
    border 0
    color $sub2
    width 100%
    height 52px
    cursor not-allowed
</style>
